.category-title{
    width:65vw;
    background-color: #a0ce95;
    color: #49944b;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    margin: 10px auto;

}

@media (max-device-width: 420px) {
    .category-title{
        width:80vw;

    }
}