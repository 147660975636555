.article-container{
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: space-around;
    width: 20vw;
    min-height: 40vh;
    max-height: 50vh;
    padding:20px;
}

.photo-container{
    width:10vw;
    height: 20vh;
}

.article-photo {
    /*max-width: 100%;*/
    /*min-height: 1px;*/
    /*margin: 0;*/
    /*vertical-align: top;*/
    /*border:0;*/
    float: left;
    width:  20vw;
    height: 20vh;
    object-fit: cover;
    cursor: pointer;
}

.article-title {
    color: #84a48c;
    font-size: large;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 5vh;
}

.article-content{
    margin-top: 0.5em;
    font-size: 1em;
    margin-bottom: 1em;
    box-sizing: inherit;
    min-height: 7vh;
    max-height: 12vh;
    overflow: hidden;
}

.continue{
    height: 3vh;
}

@media (min-width: 421px) and (max-width: 1023px){
    .article-container{
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-around;
        flex-wrap: nowrap;
        width: 25vw;
        padding:20px;
    }

    .photo-container{
        width:25vw;
        height: 20vh;
    }

    .article-photo {
        float: left;
        width:  25vw;
        height: 20vh;
        object-fit: cover;
        cursor: pointer;
    }
}

@media (max-device-width: 420px) {

    .article-container{
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: nowrap;
        width: calc(95vw - 20px);
        min-height: 50vh;
        max-height: 60vh;
        padding:14px;
        margin-bottom: 3vw;
        justify-content: flex-start;
    }

    .article-title {
        color: #84a48c;
        cursor: pointer;
        margin: 15px 15px 15px 15px;
    }

    .photo-container{
        width:inherit;
        height: 20vh;
    }

    .article-photo {
        float: left;
        width:  inherit;
        height: 20vh;
        object-fit: cover;
        cursor: pointer;
    }

    .article-content{
        margin-top: 0.5em;
        font-size: 1em;
        margin-bottom: 1em;
        box-sizing: inherit;
        min-height: 11vh;
        max-height: 19vh;
        overflow: hidden;
    }
}